import {
    GoogleAuthProvider, onAuthStateChanged,
    signInWithPopup, signOut
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore"
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { auth, db } from "../../firebase/Firebase";
import { UserContext } from "../../context/UserContext";

// design libs
import { Fab, Typography, Grid, Card, CardContent } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google"
import Swal from "sweetalert2";

function Login() {
    // global parameters
    const navigate = useNavigate();
    const [pageState, setPageState] = useState("loading");
    const { saveUserData, checkLogin } = useContext(UserContext);

    // Click handlers
    async function loginClickHandler() {
        // get auth provider (google)
        const googleProvider = new GoogleAuthProvider();

        // register user on Firebase Auth
        try {
            await signInWithPopup(auth, googleProvider);
            // console.log("Response from signInWithPopup");
            // console.log(response);
        }
        catch (e) {
            console.error("Error while sign in (auth)");
            console.error(e);
        }
    }

    // login listener
    useEffect(() => {
        onAuthStateChanged(auth, handleUserStateChanged);
    }, []);

    async function handleUserStateChanged(firebaseUser) {
        if (firebaseUser) {
            const isRegistered = await userExists(firebaseUser.email);
            if (!isRegistered) {
                // notify
                setPageState(firebaseUser);
                console.log("Autenticado pero no registrado");
                Swal.fire({
                    title: "Error",
                    text: "Este usuario no tiene permitido el acceso a Voyager. Por favor, contáctese con el administrador",
                    icon: "error"
                });

                // sign out and redirect to login
                await signOut(auth);
                localStorage.clear();
                console.log("out");
                navigate("/login");
            }
            else {
                console.log("Autenticado y registrado")

                // verificar si los datos de firebase coinciden con los de pg
                const pgRes = await checkLogin(firebaseUser.email);
                if (pgRes.ok !== true) {
                    Swal.fire({
                        title: "Error",
                        text: "Este usuario no esta registrado en Voyager. Por favor, contáctese con el administrador",
                        icon: "error"
                    });
                    // sign out and redirect to login
                    await signOut(auth);
                    localStorage.clear();
                    console.log("out");
                    navigate("/login");
                }
                else {
                    // obtener y guardar los datos
                    const user = await getUserData(firebaseUser.email);
                    saveUserData(user);

                    // redirigir dependiendo de su rol
                    if (user.user_role === "admin")
                        navigate("/users");
                    if (user.user_role === "employee" || user.user_role === "student")
                        navigate("/")
                }
            }
        }
        else {
            setPageState(null);
        }
    }

    async function userExists(email) {
        const docRef = doc(db, "users", email);
        const resp = await getDoc(docRef);
        return resp.exists();
    }

    async function getUserData(email) {
        const docRef = doc(db, "users", email);
        const resp = await getDoc(docRef);
        //console.log(resp.data());
        return resp.data();
    }

    // render component
    if (pageState === "loading") {
        return (
            <p>Loading...</p>
        );
    }
    else {
        return (
            <Grid
                container
                direction={"column"}
                alignItems={"center"}
                alignContent={"center"}
            >
                <Grid item xs={3}>
                    <Card
                        sx={{ mt: 5 }}
                        style={{
                            backgroundColor: "#1e272e",
                            padding: "1rem"
                        }}
                    >
                        <CardContent >
                            <Typography variant="h4" textAlign="center" color={"white"}>
                                Bienvenido!
                            </Typography>
                            <br />
                            <Typography variant="5" textAlign="center" color={"white"}>
                                Inicia sesión para continuar
                            </Typography>
                            <br /><br />
                            <Fab color="primary" variant="extended" size="medium" onClick={loginClickHandler}>
                                <GoogleIcon sx={{ mr: 2 }} fontSize="small" />
                                Continue with Google
                            </Fab>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default Login;