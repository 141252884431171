// TaskContext.jsx
import { createContext, useState } from "react";

// * Name of the context to be used in the components
export const ListContext = createContext();

// * Component to render the context
export function ListContextProvider({ children }) {
  // ! Context to list all tasks
  // * Tasks state
  const [tasks, setTasks] = useState([]);

  // * Task state
  const [task, setTask] = useState([]);

  // * Server URL to consume the API
  const SERVER_URL =
    process.env.REACT_APP_SERVER_URL || "http://localhost:4000/";

  // * Load all tasks
  const loadAllTasks = async () => {
    const response = await fetch(`${SERVER_URL}process`);
    const data = await response.json();
    setTasks(data);
  };

  // * Load student tasks
  const loadStudentTasks = async (user_id) => {
    const response = await fetch(`${SERVER_URL}process/student/${user_id}`);
    const data = await response.json();
    setTasks(data);
  };

  // * Load worker tasks
  const loadWorkerTasks = async (user_id) => {
    const response = await fetch(`${SERVER_URL}process/worker/${user_id}`);
    const data = await response.json();
    setTasks(data);
  };

  // ! Context to list one task
  // * Load one task
  const loadOneTasks = async (token) => {
    const response = await fetch(`${SERVER_URL}history/${token}`);
    const data = await response.json();
    setTask(data);
  };

  // * Download PDF
  const downloadPDF = async (token) => {
    const response = await fetch(`${SERVER_URL}download-pdf/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    });
    if (response.ok) {
      const pdfblob = await response.blob();
      const pdfurl = URL.createObjectURL(pdfblob);
      return pdfurl;
    }
  };

  // ! Format date and time
  const formatDateOnly = (dateString) => {
    const date = new Date(dateString);
    const offsetInHours = -4;
    const adjustedDate = new Date(
      date.setHours(date.getHours() + offsetInHours)
    );
    return adjustedDate.toLocaleDateString();
  };

  const formatTimeOnly = (dateString) => {
    const date = new Date(dateString);
    const adjustedDate = new Date(date.setHours(date.getHours()));
    return adjustedDate.toLocaleTimeString();
  };

  // ! Return the context provider
  return (
    <ListContext.Provider
      value={{
        tasks: tasks,
        task: task,
        loadAllTasks: loadAllTasks,
        loadOneTasks: loadOneTasks,
        formatDateOnly: formatDateOnly,
        formatTimeOnly: formatTimeOnly,
        loadStudentTasks: loadStudentTasks,
        loadWorkerTasks: loadWorkerTasks,
        downloadPDF: downloadPDF,
      }}
    >
      {children}
    </ListContext.Provider>
  );
}
