import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  Button,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { useContext, useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";

import { auth } from "../firebase/Firebase";
import { UserContext } from "../context/UserContext";

export default function NavBar() {
  // globals
  const navigate = useNavigate();
  const { userId, isLoggedIn } = useContext(UserContext);

  // check if logged in
  useEffect(() => {
    isLoggedIn();
  }, []);

  async function handleSignOut() {
    await signOut(auth);
    localStorage.clear();
    console.log("Signed out");
    isLoggedIn();
    navigate("/login");
  }

  function SessionArea() {
    const name = localStorage.getItem("firstname");
    const lastname = localStorage.getItem("lastname");
    const role =
      localStorage.getItem("user_role") === "admin"
        ? "Administrador"
        : localStorage.getItem("user_role") === "employee"
        ? "Trabajador"
        : "Estudiante";
    let area = " - " + localStorage.getItem("area");
    if (
      localStorage.getItem("area") === "undefined" ||
      localStorage.getItem("area") === "null"
    ) {
      area = "";
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {!isMobile && (
          <Chip
            size="sm"
            variant="outlined"
            color="warning"
            label={name + " - " + role}
          />
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {isMobile ? (
          <IconButton color="inherit" size="small" onClick={handleSignOut}>
            <LogoutIcon />
          </IconButton>
        ) : (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={handleSignOut}
          >
            Cerrar sesión
          </Button>
        )}
      </>
    );
  }

  function LockedNavbar() {
    return (
      <>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "inherit",
            fontSize: "small",
            marginLeft: "5%",
          }}
        >
          {"Todos los perfiles"}
        </Link>
        <Link
          to="/my-tasks"
          style={{
            textDecoration: "none",
            color: "inherit",
            fontSize: "small",
            marginLeft: "5%",
          }}
        >
          {"Mis perfiles"}
        </Link>
      </>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Container>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              <Link
                to={
                  localStorage.getItem("user_role") === "admin" ? "/users" : "/"
                }
                style={{ textDecoration: "none", color: "#eee" }}
              >
                {userId !== null ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/UCB-logo.ico`}
                    alt="Logo"
                    style={{ height: "40px" }}
                  />
                ) : (
                  "UCB"
                )}
              </Link>
              {userId !== null ? (
                localStorage.getItem("user_role") === "employee" ? (
                  <LockedNavbar />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Typography>
            {userId !== null ? <SessionArea /> : ""}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
