import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Swal from "sweetalert2";
import "./UserList.css";

function UserList() {
    // globals
    const { getAllUsers, users, deleteUserById, isLoggedIn, userId } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        // if user is not logged in, redirect
        isLoggedIn();
        if (userId === null) {
            console.log("user not logged in");
            navigate("/login");
        }

        // if user is not Admin, redirect
        const role = localStorage.getItem("user_role");
        if (role !== "admin") {
            navigate("/");
        }

        // Proceed
        // load all users when component is mounted
        getAllUsers();
    }, []);

    // button handlers
    const handleNewUserClick = () => {
        navigate("/users/create");
    }

    const handleEditUserClick = (user_id) => {
        navigate("/users/"+ user_id + "/edit");
    }

    const handleDeleteUserClick = async (user_id) => {
        Swal.fire({
            title: "¿Eliminar este usuario?",
            text: "Esta operación no se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserById(user_id).then(
                    (res) => {
                        console.log("Result en invoker: ", res);
                        if(res.ok){
                            Swal.fire({
                                title: "Eliminado!",
                                text: "El usuario fue eliminado.",
                                icon: "success"
                            }).then(() => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            });    
                        }
                        else{
                            Swal.fire({
                                title: "Error",
                                text: "El usuario que intenta eliminar aun tiene procesos pendientes.",
                                icon: "error"
                            }).then(() => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            });    
                        }
                    }
                );
            }
        });
    }

    // component render
    return (
        <>
            <div className="lead">
                <h1>Usuarios</h1>
                <div className="spacer"></div>
                <Button variant="contained" color="success" style={{ height: "100%" }} onClick={handleNewUserClick}>
                    Nuevo usuario
                </Button>
            </div>
            {
                users.length > 0 ? (
                    users.map((user) => (
                        <Card className="card" key={user.user_id}>
                            <CardContent>
                                <Grid container spacing={2} className="container">
                                    <Grid item xs={12} sm={6} md={3} className="grid-item">
                                        <div className="card-content-item">
                                            <Typography variant="caption" className="typography-caption">ID Usuario:</Typography>
                                            <Typography>{user.user_id}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} className="grid-item">
                                        <div className="card-content-item">
                                            <Typography variant="caption" className="typography-caption">Nombre/Apellido:</Typography>
                                            <Typography>{user.firstname + " " + user.lastname}</Typography>

                                            <Typography variant="caption" className="typography-caption">Rol:</Typography>
                                            <Typography>{(user.user_role === "admin") ? "Administrador" : (user.user_role === "employee") ? "Trabajador" : "Estudiante"}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} className="grid-item">
                                        <div className="card-content-item">
                                            <Typography variant="caption" className="typography-caption">Email:</Typography>
                                            <Typography>{user.mail}</Typography>

                                            <Typography variant="caption" className="typography-caption">Departamento/Area:</Typography>
                                            <Typography>{user.area || "No aplica"}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} className="grid-item">
                                        <div className="card-content-item">
                                            <Grid container className="button-container">
                                                <Button variant="contained" color="inherit" className="button" onClick={() => handleEditUserClick(user.user_id)} style={{ color: "black" }}>
                                                    Editar
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button variant="contained" color="error" className="button" onClick={() => handleDeleteUserClick(user.user_id)}>
                                                    Eliminar
                                                </Button>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))
                )
                    : (
                        <Typography>No se encontraron registros.</Typography>
                    )
            }
        </>
    );
}

export default UserList;