import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export default function NewProcess() {
  // Task State
  const [task, setTask] = useState({
    document_id: "",
    student: "",
    responsible: "",
    profile_name: "",
    department: "",
    description: "",
  });

  // state to store autocomplete input data
  const [student, setStudent] = useState({ label: "", id: 0 });

  // Load state
  const [loading, setLoading] = useState(false);
  // Add new state variable for downloading
  const [downloading, setDownloading] = useState(false);

  const [file, setFile] = useState(null);
  const [isPdfFile, setIsPdfFile] = useState(false);

  // Dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  // Add a new state for the PDF URL
  const [pdfUrl, setPdfUrl] = useState(null);

  // Redirect
  const navigate = useNavigate();
  const params = useParams();

  // Server URL
  const SERVER_URL =
    process.env.REACT_APP_SERVER_URL || "http://localhost:4000/";

  // options to check login and to get all students
  const { getAllStudents, users, isLoggedIn, userId } = useContext(UserContext);

  useEffect(() => {
    // if user is not logged in, redirect
    isLoggedIn();
    if (userId === null) {
      console.log("user not logged in");
      navigate("/login");
    }

    // if user is not Employee, redirect
    const role = localStorage.getItem("user_role");
    if (role !== "employee") {
      navigate("/");
    }

    // Proceed
    getAllStudents();
  }, []);

  const students = users.map((user, index) => {
    return {
      key: index,
      label: user.firstname + " " + user.lastname,
      id: user.user_id,
    };
  });

  // Send data to server to create a new process
  const handleSubmit = async (e) => {
    e.preventDefault();
    task.student = student.id;
    task.responsible = localStorage.getItem("user_id");
    task.department = localStorage.getItem("area");

    // Check if all fields are filled
    if (
      !task.document_id ||
      !task.responsible ||
      !task.profile_name ||
      !task.department ||
      !task.student ||
      !task.description
    ) {
      setDialogMessage("Complete Datos.");
      setDialogOpen(true);
      return;
    }
    // Check if file is selected
    if (!file) {
      setDialogMessage("Seleccione Archivo PDF.");
      setDialogOpen(true);
      return;
    }

    // Loading state
    setLoading(true);
    // Prepare data to send
    const fromData = new FormData();
    fromData.append("document_id", task.document_id);
    fromData.append("student", task.student);
    fromData.append("responsible", task.responsible);
    fromData.append("profile_name", task.profile_name);
    fromData.append("department", task.department);
    fromData.append("description", task.description);
    fromData.append("pdf", file);

    console.log(fromData);

    // Send data to server to create a new task
    const response = await fetch(`${SERVER_URL}upload-pdf`, {
      method: "POST",
      body: fromData,
    });

    if (response.ok) {
      console.log(response);
      // Get the PDF URL from the response and update the state
      const pdfBlob = await response.blob();

      // Create a URL for the PDF file
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);

      // Open the dialog to show the download button
      setDialogMessage("Archivo PDF recibido");
      setDialogOpen(true);
    } else {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        const errorJson = await response.json();
        // * Server side validation error
        console.log("Error JSON:", errorJson);

        let banner = "";
        if (errorJson.message === "document_id already exists") {
          banner = "El ID del documento ya existe";
        } else {
          banner = "Error en el servidor";
        }
        setDialogMessage("Error: " + banner);
      } else {
        setDialogMessage("Error en el servidor");
      }

      setDialogOpen(true);
    }

    // Loading state
    setLoading(false);
    // Redirect to home
    //navigate("/");
  };

  const handleChange = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      setDialogMessage("Seleccione Archivo PDF.");
      setDialogOpen(true);
      return;
    }
    setFile(file);
    setIsPdfFile(file.type === "application/pdf");
    console.log(file);
  };

  const handleFileDelete = () => {
    setFile(null);
    setIsPdfFile(false);
    console.log(file);
  };

  const handleCancelButtonClick = () => {
    navigate("/");
  };

  const uploadButtonText = isPdfFile ? file.name : "Cargar PDF";
  const uploadButtonColor = isPdfFile ? "error" : "secondary";

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <Card
          sx={{ mt: 5 }}
          style={{
            backgroundColor: "#1e272e",
            padding: "1rem",
          }}
        >
          <Typography variant="5" textAlign="center" color="white">
            Nuevo Proceso
          </Typography>
          <CardContent style={{ width: "90%", margin: "0 auto" }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="ID Documento"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                      }}
                      name="document_id"
                      value={task.document_id}
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                        disabled: loading,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <Autocomplete
                      options={students}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => setStudent(newValue)}
                      value={student}
                      fullWidth
                      sx={{ display: "block", margin: ".5rem 0" }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="filled"
                            label="Estudiante"
                            required
                            inputProps={{
                              ...params.inputProps,
                              style: { color: "white" },
                              disabled: loading,
                            }}
                            InputLabelProps={{
                              style: {
                                color: "white",
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="Tipo de Proceso"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                        "& .MuiInputBase-input": { color: "white" },
                      }}
                      name="profile_name"
                      value={task.profile_name}
                      onChange={handleChange}
                      select
                      inputProps={{
                        style: { color: "white" },
                        disabled: loading,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem value="Idea de Proyecto">
                        Idea de Proyecto
                      </MenuItem>
                      <MenuItem value="Perfil de Grado">
                        Perfil de Grado
                      </MenuItem>
                      <MenuItem value="Marco Teorico">Marco Teorico</MenuItem>
                      <MenuItem value="Marco Practico y Conclusion">
                        Marco Practico y Conclusion
                      </MenuItem>
                      <MenuItem value="Proyecto Finalizado">
                        Proyecto Finalizado
                      </MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      disabled
                      variant="filled"
                      fullWidth
                      label="Responsable"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                        "& input.Mui-disabled": {
                          WebkitTextFillColor: "#a8a8a8",
                        },
                      }}
                      name="responsible"
                      value={
                        localStorage.getItem("firstname") +
                        " " +
                        localStorage.getItem("lastname")
                      }
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      disabled
                      variant="filled"
                      fullWidth
                      label="Departamento"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                        "& input.Mui-disabled": {
                          WebkitTextFillColor: "#a8a8a8",
                        },
                      }}
                      name="department"
                      value={localStorage.getItem("area")}
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div>
                    <label
                      htmlFor="description"
                      style={{
                        color: "white",
                        display: "block",
                        marginBottom: ".5rem",
                      }}
                    >
                      Descripción
                    </label>
                    <TextareaAutosize
                      id="description"
                      name="description"
                      value={task.description}
                      onChange={handleChange}
                      minRows={3}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                        borderRadius: "4px",
                        // padding: "18.5px 14px",
                        color: "white",
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        borderColor: "transparent",
                        // boxShadow:
                        //   "0 0 0 100px rgba(255, 255, 255, 0.15) inset",
                        disabled: loading,
                        resize: "none",
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      borderTop: "1px solid #eee",
                      paddingTop: "4px",
                    }}
                  ></div>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div className="card-content-item">
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        {file ? (
                          <Button
                            variant="contained"
                            color={uploadButtonColor}
                            onClick={handleFileDelete}
                            disabled={loading}
                            style={{
                              flexGrow: 1,
                              width: "100%",
                              fontWeight: "bold",
                            }}
                          >
                            {/* {uploadButtonText} */}
                            {uploadButtonText.length > 7
                              ? uploadButtonText.substring(0, 7) + "... X"
                              : uploadButtonText}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            style={{
                              flexGrow: 1,
                              width: "100%",
                              fontWeight: "bold",
                            }}
                          >
                            Cargar PDF
                            <input
                              type="file"
                              style={{
                                display: "none",
                              }}
                              onChange={handleFileInputChange}
                            />
                          </Button>
                        )}
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={loading}
                              style={{
                                flexGrow: 1,
                                width: "100%",
                                fontWeight: "bold",
                              }}
                            >
                              {loading ? (
                                <CircularProgress color="primary" size="1rem" />
                              ) : (
                                "ENVIAR"
                              )}
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              color="error"
                              className="button"
                              onClick={handleCancelButtonClick}
                              style={{
                                flexGrow: 1,
                                width: "100%",
                                fontWeight: "bold",
                              }}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        {/* <DialogTitle>{dialogMessage}</DialogTitle> */}
        <DialogContent>
          {/* Show CircularProgress when downloading, otherwise show the dialog message */}
          {downloading ? (
            <CircularProgress />
          ) : (
            <Typography>{dialogMessage}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {/* Show the Download PDF button when the PDF URL is available, otherwise show the OK button */}
          {pdfUrl ? (
            <Button
              variant="contained"
              color="primary"
              href={pdfUrl}
              download={`${student.label}.pdf`}
              onClick={() => {
                setDialogOpen(false);
                setDownloading(true);
                setTimeout(() => {
                  setDownloading(false);
                  navigate("/");
                }, 1000);
              }}
            >
              Descargar PDF
            </Button>
          ) : (
            <Button variant="contained" onClick={() => setDialogOpen(false)}>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
