import { UpdateContext } from "../context/UpdateContext";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Hidden,
  MenuItem,
} from "@mui/material";
import {
  Check as CheckIcon,
  Undo as UndoIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export default function NewProcess() {
  // * Hooks to navigate and get the params
  const navigate = useNavigate();
  const params = useParams();

  // * Context to update the task
  const {
    task,
    setTask,
    handleSubmit,
    handleChange,
    handleState,
    loading,
    dialogOpen,
    dialogMessage,
    dialogTitle,
    dialogState,
    setDialogOpen,
    setDialogMessage,
    setDialogTitle,
    setDialogState,
  } = useContext(UpdateContext);

  const { isLoggedIn, userId } = useContext(UserContext);

  // * If the task is empty, redirect to protect the route
  useEffect(() => {
    isLoggedIn();
    if (userId === null) {
      console.log("user not logged in");
      navigate("/login");
    }

    // Check roles
    const role = localStorage.getItem("user_role");
    if (role === "employee" || role === "student") {
      // Proceed
      console.log("Ready to update task");
    }

    if (task.document_id === "" || task.student === "") {
      navigate("/");
    }
  }, []);

  // * Render
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <Card
          sx={{ mt: 5 }}
          style={{
            backgroundColor: "#1e272e",
            padding: "1rem",
          }}
        >
          <Typography variant="5" textAlign="center" color="white">
            Actualizar Estado de Documento
          </Typography>
          <CardContent style={{ width: "90%", margin: "0 auto" }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="ID Documento"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                      }}
                      name="document_id"
                      value={task.document_id}
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                        disabled: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="Estudiante"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                      }}
                      name="student"
                      value={task.student}
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                        disabled: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="Tipo de Proceso"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                        "& .MuiInputBase-input": { color: "white" },
                      }}
                      name="profile_name"
                      value={task.profile_name}
                      onChange={handleChange}
                      select
                      inputProps={{
                        style: { color: "white" },
                        disabled: loading,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    >
                      <MenuItem value="Idea de Proyecto">
                        Idea de Proyecto
                      </MenuItem>
                      <MenuItem value="Perfil de Grado">
                        Perfil de Grado
                      </MenuItem>
                      <MenuItem value="Marco Teorico">Marco Teorico</MenuItem>
                      <MenuItem value="Marco Practico y Conclusion">
                        Marco Practico y Conclusion
                      </MenuItem>
                      <MenuItem value="Proyecto Finalizado">
                        Proyecto Finalizado
                      </MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      disabled
                      variant="filled"
                      fullWidth
                      label="Responsable"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                        "& input.Mui-disabled": {
                          WebkitTextFillColor: "#a8a8a8",
                        },
                      }}
                      name="responsible"
                      value={
                        localStorage.getItem("firstname") +
                        " " +
                        localStorage.getItem("lastname")
                      }
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                        disabled: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      disabled
                      variant="filled"
                      fullWidth
                      label="Departamento"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                        "& input.Mui-disabled": {
                          WebkitTextFillColor: "#a8a8a8",
                        },
                      }}
                      name="department"
                      value={localStorage.getItem("area")}
                      onChange={handleChange}
                      inputProps={{
                        style: { color: "white" },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div>
                    <label
                      htmlFor="description"
                      style={{
                        color: "white",
                        fontSize: "0.8rem",
                        display: "block",
                        marginBottom: ".5rem",
                      }}
                    >
                      Descripción
                    </label>
                    <TextareaAutosize
                      id="description"
                      name="description"
                      value={task.description}
                      onChange={handleChange}
                      minRows={3}
                      placeholder="Escribe el porque recibes el documento..."
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                        borderRadius: "4px",
                        // padding: "18.5px 14px",
                        color: "white",
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        borderColor: "transparent",
                        // boxShadow:
                        //   "0 0 0 100px rgba(255, 255, 255, 0.15) inset",
                        disabled: loading,
                        resize: "none",
                      }}
                    />
                  </div>
                </Grid>

                {/* ... */}

                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      borderTop: "1px solid #eee",
                      paddingTop: "4px",
                    }}
                  >
                    <label>Recibir como:</label>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div className="card-content-item">
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-between" // Cambia de "right" a "space-between"
                    >
                      {/* Botones Aceptado y Devuelto */}
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          {/* Botón Aceptado */}
                          <Grid item>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#00a312" }}
                              disabled={loading}
                              name="status"
                              value="aceptado"
                              onClick={handleState}
                            >
                              {loading ? (
                                <CircularProgress color="primary" size="1rem" />
                              ) : (
                                <>
                                  <Hidden smDown>Aceptado</Hidden>
                                  <Hidden smUp>
                                    <CheckIcon />
                                  </Hidden>
                                </>
                              )}
                            </Button>
                          </Grid>

                          {/* Botón Devuelto */}
                          <Grid item>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#f86400" }}
                              disabled={loading}
                              name="status"
                              value="devuelto"
                              onClick={handleState}
                            >
                              {loading ? (
                                <CircularProgress color="primary" size="1rem" />
                              ) : (
                                <>
                                  <Hidden smDown>Devuelto</Hidden>
                                  <Hidden smUp>
                                    <UndoIcon />
                                  </Hidden>
                                </>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Botón Terminar */}
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#ff3c34" }}
                          disabled={loading}
                          name="status"
                          value="terminado"
                          onClick={handleState}
                        >
                          {loading ? (
                            <CircularProgress color="primary" size="1rem" />
                          ) : (
                            <>
                              <Hidden smDown>Terminado</Hidden>
                              <Hidden smUp>
                                <CloseIcon />
                              </Hidden>
                            </>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {/* ... */}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          {dialogState && (
            <Button
              variant="contained"
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              Cancelar
            </Button>
          )}
          {dialogState ? (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Confirmar
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(false)}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
