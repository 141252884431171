// TaskContext.jsx
import { useNavigate, useParams } from "react-router-dom";
import { createContext, useState } from "react";

// * Name of the context to be used in the components
export const UpdateContext = createContext();

// * Component to render the context
export function UpdateContextProvider({ children }) {
  // ! Context check token to update a task

  // * Hooks to navigate and get the params
  const navigate = useNavigate();
  const params = useParams();

  // * Tasks state
  const [task, setTask] = useState({
    document_id: "",
    student: "",
    responsible: "",
    profile_name: "",
    department: "",
    description: "",
    status: "",
  });

  // * Server URL
  const SERVER_URL =
    process.env.REACT_APP_SERVER_URL || "http://localhost:4000/";

  // * Fetch the token form params and send it to the server
  const handleOnCheckToken = async (token) => {
    try {
      const response = await fetch(`${SERVER_URL}process/${token}`);
      const data = await response.json();
      if (data.message === "Token not found") {
        // * If the token is not found, redirect to 404
        navigate("/404");
      } else if (data.message === "proceso terminado") {
        // * If the process is finished, redirect to home
        navigate(`/task/${token}/history`);
      } else {
        // * If the token is found, set the task state
        setTask({
          document_id: data.document_id,
          student: data.student_id,
          responsible: "",
          profile_name: data.profile_name,
          department: "",
          description: "",
          status: "",
        });
      }
    } catch (error) {
      console.log(error);
      navigate("/404");
    }
  };

  // ! Context that updates a task

  // * Control for the components
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogState, setDialogState] = useState(false);

  // * Handle for submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // * Check if all fields are filled
    if (
      !task.document_id ||
      //!task.responsible ||
      //!task.department ||
      !task.student ||
      !task.description ||
      !task.status
    ) {
      setDialogTitle("Error");
      setDialogMessage("Complete Datos.");
      setDialogState(false);
      setDialogOpen(true);
      return;
    }
    
    // fill auto completed data;
    task.responsible = localStorage.getItem("user_id");
    task.department = localStorage.getItem("area")

    // * Loading state
    setLoading(true);

    // * Send data to server to create a new task
    const response = await fetch(`${SERVER_URL}process/${params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    });

    // * Check if the response is ok and redirect to history page
    if (response.ok) {
      navigate(`/task/${params.id}/history`);
    } else {
      setDialogMessage("Error en el servidor");
      setDialogOpen(true);
    }

    // * Loading state
    setLoading(false);
  };

  // * Handle for buttom state
  const handleState = (e) => {
    const button = e.currentTarget;
    setTask({ ...task, [button.name]: button.value });
    setDialogTitle("Confirmar");
    setDialogMessage(
      `¿Estás seguro de que deseas cambiar el estado a ${button.value}?`
    );
    setDialogState(true);
    setDialogOpen(true);
  };

  // * Handle for task state
  const handleChange = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };

  // ! Return the context provider
  return (
    <UpdateContext.Provider
      value={{
        // * Check context
        task: task,
        setTask: setTask,
        handleOnCheckToken: handleOnCheckToken,
        // * Update context
        handleSubmit: handleSubmit,
        handleChange: handleChange,
        handleState: handleState,
        loading: loading,
        dialogOpen: dialogOpen,
        dialogMessage: dialogMessage,
        dialogTitle: dialogTitle,
        dialogState: dialogState,
        setDialogOpen: setDialogOpen,
        setDialogMessage: setDialogMessage,
        setDialogTitle: setDialogTitle,
        setDialogState: setDialogState,
      }}
    >
      {children}
    </UpdateContext.Provider>
  );
}
