// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { 
    getFirestore, collection, addDoc, 
    getDocs, doc, getDoc, query, where, 
    setDoc, deleteDoc 
} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBllCtQG1oqcNMxd-Ps3vWUk2VmG41oTew",
  authDomain: "voyager-app-c98fc.firebaseapp.com",
  projectId: "voyager-app-c98fc",
  storageBucket: "voyager-app-c98fc.appspot.com",
  messagingSenderId: "596993869599",
  appId: "1:596993869599:web:e0be9de274e8ff47d3d22a"
};

// Initialize Firebase, Auth and Firestore
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);