// design libs
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Autocomplete } from "@mui/material";
import "./Autocomplete.css";

// react libs
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";

function NewUser() {
    // state to store form's user data 
    const [message, setMessage] = useState({
        from: "",
        to: "",
        auto_to: "",
        msg: ""
    });

    const [ac, setAc] = useState({
        label: "",
        id: 0
    });

    // methods to call API
    const { getAllUsers, users } = useContext(UserContext);

    // list of options
    const students = users.map((user, index) => {
        return {key: index, label: user.firstname + " " + user.lastname, id: user.user_id}
    });

    useEffect(() => {
        getAllUsers();
    }, []);

    // form handlers
    const handleSubmit = async (e) => {
        e.preventDefault();

        message.auto_to = ac.id;
        console.log("data received:", message)

        // const response = await createUser(currentUser);
        // if (response.ok) {
        //     // register on firestore
        //     const data = await response.json();
        //     try {
        //         const docRef = doc(db, "users/" + data.mail);
        //         await setDoc(docRef, {
        //             user_id: data.user_id,
        //             mail: data.mail,
        //             firstname: data.firstname,
        //             lastname: data.lastname,
        //             user_role: data.user_role
        //         });
        //         navigate("/users");
        //     }
        //     catch (e) {
        //         console.error("Error en registro remoto (Firestore)");
        //         console.error(e);
        //     }
        // }
        // else {
        //     console.error("Error en registro local (Postgres)");
        //     alert("Error al crear el usuario!");
        // }
    }

    const handleInputChange = (e) => {
        setMessage({ ...message, [e.target.name]: e.target.value });
    }

    return (
        <>
            <h2>Autocomplete de Estudiante</h2>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                    <Card className="card" sx={{ mt: 5 }}>
                        <CardContent className="card-content">
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="From" name="from" value={message.from} required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="To" name="to" value={message.to} required
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="Autocomplete To" name="auto_to" value={message.auto_to} required
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <Autocomplete
                                            options={students}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField {...params} label="Autocomplete To" 
                                                        variant="filled" InputLabelProps={{style: {color: "white"}}} required
                                                        inputProps={{ ...params.inputProps, style: {color:"white"} }}
                                                    />
                                                );
                                            }}
                                            onChange={(event, newValue) => setAc(newValue)}
                                            value={ac}

                                            fullWidth sx={{display: "block", margin: ".5rem 0"}} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="Message" name="msg" value={message.msg} required
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} className="item-form"><hr /></Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={1} alignItems="center" justifyContent="right">
                                            <Grid item>
                                                <Button variant="contained" color="primary" type="submit" className="button">
                                                    Crear
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default NewUser;