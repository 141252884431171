import { useEffect, useContext } from "react";
import { ListContext } from "../context/ListContext";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export default function MyProcesses(){
    // * Navitate to another page
  const navigate = useNavigate();

  // * Context
  const { isLoggedIn, userId } = useContext(UserContext);
  const { tasks, loadWorkerTasks, formatDateOnly, formatTimeOnly } =
    useContext(ListContext);

  // * Load all tasks when component is mounted
  useEffect(() => {
    // check if user is logged in and check the routes the user can access
    isLoggedIn();
    if(userId === null){
      console.log("user not logged in");
      navigate("/login");  
    }
    else{
      const id = localStorage.getItem("user_id");
      const role = localStorage.getItem("user_role");
      if(role === "student"){
        navigate("/");
      }
      else if(role === "employee"){
        console.log("obteniendo tareas del trabajador");
        loadWorkerTasks(id);
      }
    }
  }, []);

  // * Render
  const CreateButton = () => {
    return (
      <Button variant="contained" color="success" 
        style={{height: "100%"}}
        onClick={() => navigate("/task/generate")}>
        Nuevo Perfil
      </Button>
    );
  }

  return (
    <>
      <div style={{display: "flex", alignItems: "center"}}>
        <h1>{"Mis Procesos"}</h1>
        <div style={{flex: 1}}></div>
        <CreateButton />
      </div>
      {tasks.length > 0 ? (
        tasks.map((task) => (
          <Card
            style={{
              margin: ".7rem",
              backgroundColor: "#1e272e",
            }}
            key={task.id}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                  <div className="card-content-item">
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      ID Documento:
                    </Typography>
                    <Typography>{task.document_id}</Typography>
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Estudiante:
                    </Typography>
                    <Typography>{task.student_id}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                  <div className="card-content-item">
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Departamento:
                    </Typography>
                    <Typography>{task.department}</Typography>
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Responsable:
                    </Typography>
                    <Typography>{task.responsible_id}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                  <div className="card-content-item">
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Tipo de proceso:
                    </Typography>
                    <Typography style={{ textAlign: "left" }}>
                      {task.profile_name}
                    </Typography>
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Descripción:
                    </Typography>
                    <Typography style={{ textAlign: "left" }}>
                      {task.description}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                  <div className="card-content-item">
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Fecha:
                    </Typography>
                    <Typography>{formatDateOnly(task.created_at)}</Typography>
                    <Typography variant="caption" style={{ opacity: 0.7 }}>
                      Hora:
                    </Typography>
                    <Typography>{formatTimeOnly(task.created_at)}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className="card-content-item">
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="inherit"
                          onClick={() =>
                            navigate(`/task/${task.token}/history`)
                          }
                          style={{
                            flexGrow: 1,
                            width: "100%",
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                          }}
                        >
                          Historial
                        </Button>
                      </Grid>
                      <Grid item xs={4} sm={4} md={6}>
                        <Chip
                          label={
                            task.status === "aceptado"
                              ? "ACEPTADO"
                              : task.status === "devuelto"
                              ? "DEVUELTO"
                              : task.status === "terminado"
                              ? "TERMINADO"
                              : "ESTADO"
                          }
                          style={{
                            flexGrow: 1,
                            width: "100%",
                            backgroundColor:
                              task.status === "aceptado"
                                ? "#00a312"
                                : task.status === "devuelto"
                                ? "#f86400"
                                : task.status === "terminado"
                                ? "#727976"
                                : "warning",
                            color: "#eee",
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>No se encontraron registros.</Typography>
      )}
    </>
  );
}

