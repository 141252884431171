// design libs
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import "./NewUser.css";

// react libs
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

// firebase libs
import { db } from "../../firebase/Firebase";
import { doc, setDoc } from "firebase/firestore";

function NewUser() {
    // redirects
    const navigate = useNavigate();

    // state to store form's user data 
    const [currentUser, setCurrentUser] = useState({
        mail: "",
        firstname: "",
        lastname: "",
        user_role: "",
        area: ""
    });

    // methods to call API and to check auth
    const { createUser, isLoggedIn, userId } = useContext(UserContext);

    useEffect(() => {
        // if user is not logged in, redirect
        isLoggedIn();
        if (userId === null) {
            console.log("user not logged in");
            navigate("/login");
        }

        // if user is not Admin, redirect
        const role = localStorage.getItem("user_role");
        if (role !== "admin") {
            navigate("/");
        }

        // Proceed
    }, []);

    // form handlers
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(currentUser.user_role === "employee" && currentUser.area.length === 0){
            alert("Debe llenar el campo Area/Departamento del trabajador");
            return;
        }
        if(currentUser.user_role === "student" || currentUser.user_role === "admin"){
            currentUser.area = null;
        }
        
        const response = await createUser(currentUser);
        if(response.ok) {
            // register on firestore
            const data = await response.json();
            try{
                const docRef = doc(db, "users/" + data.mail);
                await setDoc(docRef, {
                    user_id: data.user_id,
                    mail: data.mail,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    user_role: data.user_role,
                    area: data.area
                });
                navigate("/users");
            }
            catch(e){
                console.error("Error en registro remoto (Firestore)");
                console.error(e);
            }
        }
        else{
            console.error("Error en registro local (Postgres)");
            alert("Error al crear el usuario!");
        }
    }

    const handleInputChange = (e) => {
        setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
    }

    const handleCancelButtonClick = () => {
        navigate("/users");
    }

    return (
        <>
            <h2>Nuevo usuario</h2>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                    <Card className="card" sx={{ mt: 5 }}>
                        <CardContent className="card-content">
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="Nombre" name="firstname" value={currentUser.firstname} required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="Apellido" name="lastname" value={currentUser.lastname} required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="Correo" name="mail" value={currentUser.mail} required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <FormControl fullWidth>
                                        <InputLabel id="role-input" style={{color: "white"}}>Rol</InputLabel>
                                        <Select variant="filled" sx={{ display: "block", margin: ".5rem 0", color: "white" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} 
                                            labelId="role-input" required
                                            name="user_role" value={currentUser.user_role}
                                        >
                                            <MenuItem value={"admin"}>Administrador</MenuItem>
                                            <MenuItem value={"employee"}>Trabajador</MenuItem>
                                            <MenuItem value={"student"}>Estudiante</MenuItem>
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="item-form">
                                        <TextField variant="filled" fullWidth sx={{ display: "block", margin: ".5rem 0" }}
                                            onChange={handleInputChange} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }}
                                            label="Departamento/Area (vacio si no es trabajador)" name="area" value={currentUser.area}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} className="item-form"><hr /></Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={1} alignItems="center" justifyContent="right">
                                            <Grid item>
                                                <Button variant="contained" color="primary" type="submit" className="button">
                                                    Crear
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="error" className="button" onClick={handleCancelButtonClick} style={{padding: "8% 60%"}}>
                                                    Cancelar
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default NewUser;