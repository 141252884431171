import { UpdateContext } from "../context/UpdateContext";
import { UserContext } from "../context/UserContext";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  Hidden,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function CheckProcess() {
  // Dialog content
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  // * Hooks to navigate and get the params
  const navigate = useNavigate();
  const params = useParams();

  // * Get the task and the function to check the token from the context
  const { task, handleOnCheckToken } = useContext(UpdateContext);

  // Context to check if the user is logged in
  const { isLoggedIn, userId } = useContext(UserContext);

  useEffect(() => {
    // if user is not logged in, redirect
    // isLoggedIn();
    if (!!!isLoggedIn()) {
      console.log("user not logged in");
      navigate("/login");
    }

    // if user is not Employee or Student, redirect
    const role = localStorage.getItem("user_role");
    if (role === "admin") {
      navigate("/");
    }

    // Proceed
    // * Check if there is a token in the params
    if (params.id) {
      handleOnCheckToken(params.id);
    }
  }, [params.id]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <Card
          sx={{ mt: 5 }}
          style={{
            backgroundColor: "#1e272e",
            padding: "1rem",
          }}
        >
          <Typography variant="5" textAlign="center" color="white">
            Documento
          </Typography>
          <CardContent style={{ width: "90%", margin: "0 auto" }}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="ID Documento"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                      }}
                      name="document_id"
                      value={task.document_id}
                      inputProps={{
                        style: { color: "white" },
                        disabled: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="Estudiante"
                      sx={{
                        display: "block",
                        margin: ".5rem 0",
                      }}
                      name="student"
                      value={task.student}
                      inputProps={{
                        style: { color: "white" },
                        disabled: true,
                      }}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                    />
                  </div>
                </Grid>

                {/* ... */}

                <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
                  <div className="card-content-item">
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {/* Botones Aceptado y Devuelto */}
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          {/* Botón Aceptado */}
                          <Grid item xs={12} sm={12} md={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              color="inherit"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                              // Navigate to the process history page
                              onClick={() =>
                                navigate(`/task/${params.id}/history`)
                              }
                            >
                              Historial
                            </Button>
                          </Grid>

                          {/* Botón Devuelto */}
                          <Grid item xs={12} sm={12} md={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              style={{ backgroundColor: "#685dce" }}
                              // Navigate to the process update page
                              onClick={() =>
                                navigate(`/task/${params.id}/update`)
                              }
                            >
                              Actualizar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {/* ... */}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
