import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NewProcess from "./components/NewProcess";
import ListProcess from "./components/ListProcess";
import UpdateOwner from "./components/UpdateOwner";
import HistoryProcess from "./components/HistoryProcess";
import CheckProcess from "./components/CheckProcess";
import StepHistory from "./components/StepHistory";
import Error404 from "./components/Error404";
import Menu from "./components/NavBar";
import Login from "./components/auth/Login";
import UserList from "./components/users/UserList";
import Autocomplete from "./components/tests/Autocomplete"

import { Container } from "@mui/material";

import { ListContextProvider } from "./context/ListContext";
import { UpdateContextProvider } from "./context/UpdateContext";
import { UserContextProvider } from "./context/UserContext";
import NewUser from "./components/users/NewUser";
import EditUser from "./components/users/EditUser";
import MyProcesses from "./components/MyProcesses";

export default function App() {
  return (
    <BrowserRouter>
      <UserContextProvider>
      <Menu />
      <Container>
        <Routes>
          {/* Home - My Processes */}
          <Route
            path="/" 
            element={
              <ListContextProvider>
                <ListProcess />
              </ListContextProvider>
            }
          />

          {/* General List Process */}
          <Route
            path="/my-tasks" 
            element={
              <ListContextProvider>
                <MyProcesses />
              </ListContextProvider>
            }
          />

          {/* Routes for sign in */}
          <Route
            path="/login"
            element={
                <Login />
            }
          />
          
          {/* History */}
          {/* <Route
            path="/task/:id/history"
            element={
              <ListContextProvider>
                <HistoryProcess />
              </ListContextProvider>
            }
          /> */}

          {/* Check */}
          <Route
            path="/task/:id/check"
            element={
              <UpdateContextProvider>
                <CheckProcess />
              </UpdateContextProvider>
            }
          />

          {/* Update Owner */}
          <Route
            path="/task/:id/update"
            element={
              <UpdateContextProvider>
                <UpdateOwner />
              </UpdateContextProvider>
            }
          />

          {/* Generate New Process */}
          <Route path="/task/generate" element={<NewProcess />} />

          {/* History process */}
          <Route
            path="/task/:id/history"
            element={
              <ListContextProvider>
                <StepHistory />
              </ListContextProvider>
            }
          />

          {/* Error 404 */}
          <Route path="/404" element={<Error404 />} />
          <Route path="*" element={<Navigate to="/404" />} />

          {/* UserList page */}
          <Route path="/users" element={ <UserList/> } />

          {/* NewUser page */}
          <Route path="/users/create" element={ <NewUser/> } />

          {/* EditUser page */}
          <Route path="/users/:id/edit" element={<EditUser/>} />

          {/* ComponentTests Page */}
          <Route path="/test" element={ <Autocomplete/> } />
        </Routes>
      </Container>
      </UserContextProvider>
    </BrowserRouter>
  );
}
