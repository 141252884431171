import { createContext, useState } from "react";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  // globales para el registro
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState(null);
  const [editingUser, setEditingUser] = useState({
    mail: "",
    firstname: "",
    lastname: "",
    user_role: "",
    area: "",
  });

  // globales para el API fetch
  const SERVER_URL =
    process.env.REACT_APP_SERVER_URL || "http://localhost:4000/";
  const [users, setUsers] = useState([]);

  // funciones para el registro de un usuario
  const saveUserData = (user) => {
    localStorage.setItem("user_id", user.user_id);
    localStorage.setItem("mail", user.mail);
    localStorage.setItem("firstname", user.firstname);
    localStorage.setItem("lastname", user.lastname);
    localStorage.setItem("user_role", user.user_role);
    localStorage.setItem("area", user.area);
    setUserId(user.mail);
  };

  const resetUserData = () => {
    setUser(null);
  };

  const clearUserData = () => {
    localStorage.clear();
  };

  async function isLoggedIn() {
    const mail = await localStorage.getItem("mail");
    setUserId(mail);
    console.log("UserContext email: ", userId);
    if (userId === null) return false;
    else return true;
  }

  // funciones para el API fetch
  async function getAllUsers() {
    const response = await fetch(SERVER_URL + "user");
    const data = await response.json();
    setUsers(data);
  }

  async function getAllStudents() {
    const response = await fetch(SERVER_URL + "student");
    const data = await response.json();
    setUsers(data);
  }

  async function createUser(currentUser) {
    const response = await fetch(SERVER_URL + "user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(currentUser),
    });
    return response;
  }

  async function checkLogin(mail) {
    const response = await fetch(SERVER_URL + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mail: mail,
      }),
    });
    // console.log("checkloginres", response);
    return response;
  }

  async function getUserById(id) {
    console.log("Getting user by id...");
    const response = await fetch(SERVER_URL + "user/" + id);
    const data = await response.json();
    setUser(data);
  }

  async function getEditingUser(id) {
    console.log("fetching ID");
    const response = await fetch(SERVER_URL + "user/" + id);
    const data = await response.json();
    console.log("data", data);
    if (data.message) {
      console.log("did not work");
      return false;
    } else {
      setEditingUser({
        mail: data.mail,
        firstname: data.firstname,
        lastname: data.lastname,
        user_role: data.user_role,
        area: data.area,
      });
      console.log("worked");
      return true;
    }
  }

  async function updateUser(user_id) {
    // * Send data to server to create a new task
    const response = await fetch(`${SERVER_URL}user/${user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editingUser),
    });

    return response;
  }

  async function deleteUserById(id) {
    console.log("Deleting user by id...");
    const response = await fetch(SERVER_URL + "user/" + id, {
      method: "DELETE",
    });
    console.log("Delete response: ", response);
    return response;
    // const data = await response.json();
    // setUser(data);
  }

  // renderizar para que pueda ser utilizado por cualquier hijo
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userId,
        resetUserData,
        saveUserData,
        clearUserData,
        isLoggedIn,
        checkLogin,

        users,
        getAllUsers,
        createUser,
        getUserById,
        deleteUserById,
        getAllStudents,
        editingUser,
        setEditingUser,
        getEditingUser,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
