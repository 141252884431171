import { ListContext } from "../context/ListContext";
import { UserContext } from "../context/UserContext";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  Card,
  CardContent,
  Grid,
  Typography,
  Chip,
  StepButton,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

export default function StepHistory() {
  const steps = [
    "Paso 1",
    "Paso 2",
    "Paso 3",
    "Paso 4",
    "Paso 5",
    "Paso 6",
    "Paso 7",
    "Paso 8",
  ];

  // * Param to get the token
  const params = useParams();

  // * Navitate to another page
  const navigate = useNavigate();

  // * Context
  const { task, loadOneTasks, formatDateOnly, formatTimeOnly, downloadPDF } =
    useContext(ListContext);

  // Context to check auth
  const { isLoggedIn, userId } = useContext(UserContext);

  const [finished, setFinished] = useState(false);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  // ! Contaxt for Step
  // * State to control the active step
  const [activeStep, setActiveStep] = useState(-1);

  // Download PDF
  const handleDownloadPDF = async () => {
    const myFile = await downloadPDF(params.id);
    console.log(myFile);
    const studentName = task.length > 0 ? task[0].student_id : "student";
    const docId = task.length > 0 ? task[0].document_id : "document";
    const a = document.createElement("a");
    a.href = myFile;
    a.download = `${studentName}_${docId}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    // if user is not logged in, redirect
    isLoggedIn();
    if (userId === null) {
      console.log("user not logged in");
      navigate("/login");
    }

    // Check roles
    const role = localStorage.getItem("user_role");
    if (role === "employee" || role === "student") {
      // Proceed
      // * Load a task by id when the component is mounted and when the id changes
      if (params.id) {
        loadOneTasks(params.id);
      }
    }
    // if user is not Employee or Student, redirect
    else {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (task.length > 0) {
      let newActiveStep = -1;
      task.forEach((taskItem) => {
        if (taskItem.status_new === "aceptado") {
          newActiveStep++;
        } else if (taskItem.status_new === "devuelto") {
          newActiveStep--;
        } else if (taskItem.status_new === "terminado") {
          newActiveStep++;
          setFinished(true);
        }
      });
      setActiveStep(newActiveStep);

      console.log(newActiveStep);
      console.log(steps.length);
      console.log(finished);
      console.log(task);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const firstTaskStatus = task.length > 0 ? task[0].status_new : null;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Card
        style={{
          margin: ".7rem",
          backgroundColor: "#1e272e",
        }}
      >
        <CardContent
          sx={{
            overflowX: "auto", // Agregue esto para permitir el desplazamiento horizontal
            "&::-webkit-scrollbar": {
              width: "0.4em",
              height: "0.4em",
              borderRadius: "2px",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, .5)",
              outline: "1px solid slategrey",
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="card-content-item">
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      sx={{
                        "& .MuiStepIcon-root": {
                          color:
                            index < activeStep
                              ? "#1565c0"
                              : index === activeStep
                              ? firstTaskStatus === "aceptado"
                                ? "#00a312"
                                : firstTaskStatus === "devuelto"
                                ? "#f86400"
                                : firstTaskStatus === "terminado"
                                ? "#727976"
                                : "#727976"
                              : "#727976",
                        },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                          {
                            color: "#FFFFFF", // activo
                          },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "#FFFFFF", // comopletados
                          },
                        "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                          color: "#AAAAAA", // general
                        },
                      }}
                    >
                      <StepButton>{label}</StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {task.length > 0 ? (
        task
          .filter((_, index) => showAll || index === 0)

          .map((task) => (
            <Card
              style={{
                margin: ".7rem",
                backgroundColor: "#1e272e",
              }}
              key={task.history_id}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                    <div className="card-content-item">
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        ID Documento:
                      </Typography>
                      <Typography>{task.document_id}</Typography>
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Estudiante:
                      </Typography>
                      <Typography>{task.student_id}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                    <div className="card-content-item">
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Departamento:
                      </Typography>
                      <Typography>{task.department_new}</Typography>
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Responsable:
                      </Typography>
                      <Typography>{task.responsible_new}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                    <div className="card-content-item">
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Tipo de proceso:
                      </Typography>
                      <Typography style={{ textAlign: "left" }}>
                        {task.profile_name_new}
                      </Typography>
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Descripción:
                      </Typography>
                      <Typography style={{ textAlign: "left" }}>
                        {task.description_new}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ color: "#eee" }}>
                    <div className="card-content-item">
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Fecha:
                      </Typography>
                      <Typography>{formatDateOnly(task.updated_at)}</Typography>
                      <Typography variant="caption" style={{ opacity: 0.7 }}>
                        Hora:
                      </Typography>
                      <Typography>{formatTimeOnly(task.updated_at)}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="card-content-item">
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent="left"
                      >
                        <Grid item xs={6} sm={6} md={6}>
                          <Chip
                            label={
                              task.status_new === "aceptado"
                                ? "ACEPTADO"
                                : task.status_new === "devuelto"
                                ? "DEVUELTO"
                                : task.status_new === "terminado"
                                ? "TERMINADO"
                                : "ESTADO"
                            }
                            style={{
                              flexGrow: 1,
                              width: "100%",
                              backgroundColor:
                                task.status_new === "aceptado"
                                  ? "#00a312"
                                  : task.status_new === "devuelto"
                                  ? "#f86400"
                                  : task.status_new === "terminado"
                                  ? "#727976"
                                  : "warning",
                              color: "#eee",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))
      ) : (
        <Typography>No se encontraron registros.</Typography>
      )}
      <Grid item xs={12} sm={12} md={12} style={{ color: "#eee" }}>
        <div className="card-content-item">
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between" // Cambia de "right" a "space-between"
          >
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  {isMobile ? (
                    <IconButton
                      onClick={toggleShowAll}
                      sx={{
                        margin: ".7rem",
                        color: "white",
                        backgroundColor: "#1976D2",
                      }}
                    >
                      <UnfoldMoreIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={toggleShowAll}
                      sx={{ margin: ".7rem" }}
                    >
                      {showAll ? "Contraer" : "Ver mas"}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {isMobile ? (
                    <IconButton
                      onClick={() => navigate(`/task/${params.id}/check`)}
                      sx={{
                        margin: ".7rem",
                        backgroundColor: "#685dce",
                        color: "white",
                      }}
                    >
                      <SyncAltIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => navigate(`/task/${params.id}/check`)}
                      sx={{ margin: ".7rem", backgroundColor: "#685dce" }}
                    >
                      Actualizar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {isMobile ? (
                <IconButton
                  onClick={handleDownloadPDF}
                  sx={{
                    margin: ".7rem",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  <GetAppIcon />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleDownloadPDF}
                  sx={{
                    margin: ".7rem",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  DESCARGAR PDF
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
